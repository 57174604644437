import { useUpdateModalState } from 'public/src/pages/cart_v2/utils/popoverManager/useUpdateModalState.js'
import { getQuickAddCartInstance, plugins } from 'public/src/pages/components/product/quick_add_v3/common.js'
import { click_same_sellers_block } from 'public/src/pages/cart_v2/analysis/item/sameSellersLabel.js'
import { useStore } from 'public/src/pages/cart_v2/hooks/useStore'
import { SToast } from '@shein-aidc/sui-toast/mobile'
import UserInfoManager from 'public/src/services/UserInfoManager'

const getHtmlPrice = (amountWithSymbol, priceShowStyle) => `<b style="${priceShowStyle ? 'font-size:10px' : 'font-weight:500'}">${amountWithSymbol?.replace(priceShowStyle, `<em style="font-size:17px;line-height:1">${priceShowStyle}</em>`)}</b>`

export function useSameSellersLabelEvent() {
  const { dispatch, state } = useStore()
  const { registerModalState, updateModalState } = useUpdateModalState()
  
  const replaceItem = async (context, currentItem, events, isShowOverLimitToast) => {
    try {
      const reqParams = {
        is_checked: currentItem.value.is_checked,
        append_id_list: currentItem.value.appendIds,
        sku_code: context?.skuInfo?.sku_code,
        id: currentItem.value.id,
        mall_code: context?.mallCode,
        // quantity: context?.quantity,
        quantity: currentItem.value?.quantity,
        goods_id: context?.productInfo?.goods_id,
      }
      const res = await dispatch('fetchCartUpdateAttr', reqParams)
      if (res.code == '0') {
        dispatch('showOverLimitToast', { id: currentItem.value.id, isShowOverLimitToast })
        events?.onUpdated?.()
        SToast( state.language?.SHEIN_KEY_PWA_37176 || 'Successfully replaced!')
        return true
      } else {
        SToast(res.msg)
      }
    } catch (error) {
    }
  }

  const openQuickAddCartReplace = async (targetItem, currentItem, events, isShowOverLimitToast, sence = 'cart') => {
    let addBagStatus = false
    getQuickAddCartInstance().open({
      goods_id: targetItem?.goods_id,
      mallCode: targetItem?.mallCode,
    }, {
      excludePlugins: ['syncCartInfo'],
      analysisConfig: {
        code: 'cart',
        from: 'cart',
        source: 'page',
        ...(targetItem?.analysisConfig || {}),
      },
      extendedParam: {
        quantity: +currentItem.value.quantity,
        showEstimatedPrice: 1,
      },
      featureConfig: {
        ...(targetItem?.featureConfig || {}),
      },
      detailUrlExtendParam: {
        ...(targetItem?.detailUrlExtendParam || {}),
      },
      callbacks: {
        onDrawerStatusChange: (show) => {
          if (show) {
            registerModalState()
          } else {
            updateModalState()
          }
        },
        onAddCartFinished: (params) => {
          addBagStatus = params.isSuccess
          if (addBagStatus) {
            dispatch('fetchCartIndex')
          }
          if(sence == 'buybox') {
            state.buyBoxDrawerInstance?.updateShowDrawer(false)
          }
        },
      },
      plugins: [
        plugins.sameSellersReplacePlugin({
          options: {
            cb: {
              replaceItem: async (context) => {
                const result = await replaceItem(context, currentItem, events, isShowOverLimitToast)
                if(sence == 'buybox') {
                  state.buyBoxDrawerInstance?.updateShowDrawer(false)
                }
              }
            }
          }
        })
      ]
    })
  }

  const onClick = async ({ itemOpts: { data, event }, options: { index = 1, events } = {}, analysis = {} }) => {
    const sameGroupLowPriceInfo = data?.value?.aggregateProductBusiness?.sameGroupLowPriceInfo

    if(sameGroupLowPriceInfo?.isQuickAddCart == 1 && sameGroupLowPriceInfo?.lowestPriceInfo?.goodsId) { // 快加车弹窗repleace
      openQuickAddCartReplace({
        goods_id: sameGroupLowPriceInfo?.lowestPriceInfo?.goodsId,
        mallCode: sameGroupLowPriceInfo?.lowestPriceInfo?.mallCode,
        // goods_id: '13179584',
        // mallCode: '1',
      }, data, events, true)
    } else if(sameGroupLowPriceInfo?.isQuickAddCart == 0) { // 跳转buybox推荐弹窗
    // } else if(true) { // 跳转buybox推荐弹窗
      const buyBoxDrawerInstance = state.buyBoxDrawerInstance
      buyBoxDrawerInstance?.refreshData?.()
      state.buyBoxPriceTop = {
        text: getHtmlPrice(
          data.value.aggregateProductBusiness?.priceData?.unitPrice?.price?.amountWithSymbol,
          data.value.aggregateProductBusiness?.priceData?.unitPrice?.price?.priceShowStyle
        ) + ' ' + (sameGroupLowPriceInfo?.replacedPriceTip || ''),
        data,
        style: {
          color: data.value.aggregateProductBusiness?.priceData?.unitPrice?.color || '#fff',
        }
      }
      const { is_paid = 0 } = await UserInfoManager.get({ key: 'isPaid', actionType: 'SameSellersLabelOpenBuyBox' })
      buyBoxDrawerInstance?.open?.({
        analysisConfig: {
          goodsId: data.value?.product?.goods_id,
        },
        isPaidUser: is_paid, // 当前账号是否为付费会员
        requestParams: {
          goods_id: data.value?.product?.goods_id,
          cat_id: data.value?.cat_id,
          goods_sn: data.value?.product?.goods_sn,
          mall_code: data.value?.mall_code,
          pageKey: 'page_cart',
        }
      })
    }
  }
  const onReport = async ({ itemOpts: { data, event }, options: { index = 1 } = {}, analysis = {} }) => {
    click_same_sellers_block(data.value, (defaultData) => {
      const data = {
        ...defaultData,
      }
      if(analysis?.click_same_sellers_block){
        return analysis.click_same_sellers_block(data)
      }
      return data
    })
  }
  const onExpose = async ({ itemOpts: { data, event }, options: { index = 1 } = {}, analysis = {} }) => {

  }

  return {
    onClick,
    onReport,
    onExpose,
    openQuickAddCartReplace,
  }
}
